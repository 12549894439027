<template>
  <b-container fluid>
    <b-row>
      <b-col style="padding: 0">
        <div class="wrapper">
          <div class="title">
            <span>KAPMOBILE.ID</span>
          </div>
          <div class="sub-title">
            <span
              >Masukkan kode OTP yang telah<br />
              dikirimkan ke email
              <span class="bold">{{ email }}</span></span
            >
          </div>
          <div class="form">
            <b-form-input
              v-model="otp"
              type="number"
              placeholder="Kode OTP"
              class="inputs"
            ></b-form-input>

            <b-form-input
              v-model="password"
              type="password"
              class="inputs"
              placeholder="Password Baru"
            ></b-form-input>

            <div class="pesan">
              <span>{{ pesan }}</span>
            </div>

            <b-form-input
              @input="cek"
              v-model="password2"
              type="password"
              class="inputs"
              placeholder="Konfirmasi Password Baru"
            ></b-form-input>

            <div class="button-wrapper">
              <b-button v-if="loading == true"><white-spinner /></b-button>
              <b-button @click="resetPass" v-else-if="same == true"
                >Reset Password</b-button
              >
              <b-button v-else disabled>Reset Password</b-button>
            </div>
          </div>

          <div class="modal">
            <b-modal hide-footer hide-header centered id="modal-success">
              <div
                class="contents"
                style="display: flex; align-items: center;justify-content;center; padding:1% 4%;"
              >
                <span
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    color: #061737;
                  "
                  >Password berhasil diubah. Klik tombol "OK" untuk melanjutkan
                  ke halaman
                  <span style="color: #327ff2; font-weight: 700"
                    >Sign In</span
                  ></span
                >
                <img src="../../assets/vector/checked.svg" alt="" />
              </div>

              <div
                class="button-wrapper"
                style="display: flex; width: 100%; justify-content: center"
              >
                <b-button
                  @click="() => this.$router.push('/')"
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    width: 25%;
                    border: none;
                    margin-top: 20px;
                    height: 40px;
                  "
                  >OK</b-button
                >
              </div>
            </b-modal>

            <b-modal hide-footer hide-header centered id="modal-failed">
              <div
                class="contents"
                style="
                  text-align:center
                  padding: 1% 4%;
                "
              >
                <span
                  v-if="loadingModal == false"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    color: #061737;
                  "
                  >Kode
                  <span style="color: #e0544e; font-weight: 700"
                    >OTP salah</span
                  >
                  atau telah
                  <span style="color: #e0544e; font-weight: 700"
                    >kadaluarsa!</span
                  >
                  Silahkan klik tombol OK untuk mengirim ulang kode OTP.</span
                >
                <span
                  v-else
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    color: #061737;
                  "
                  >Kode OTP sedang dikirim ke email anda</span
                >
              </div>

              <div
                class="button-wrapper"
                style="display: flex; width: 100%; justify-content: center"
              >
                <b-button
                  @click="doResend"
                  v-if="loadingModal == false"
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    width: 25%;
                    border: none;
                    margin-top: 20px;
                    height: 47px;
                  "
                  >OK</b-button
                >
                <b-button
                  disabled
                  v-else
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    width: 25%;
                    border: none;
                    margin-top: 20px;
                    height: 47px;
                  "
                  ><white-spinner
                /></b-button>
              </div>
            </b-modal>
          </div>
        </div>
      </b-col>

      <b-col style="padding: 0">
        <div class="wrapper2">
          <!-- <div class="ads">iklan</div>
          <div class="ads">iklan</div>
          <div class="ads">iklan</div> -->
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "@/ip_config.js";
import whiteSpinner from "../../components/spinner/white-spinner.vue";

export default {
  components: { whiteSpinner },
  data() {
    return {
      email: "",
      otp: "",
      same: false,
      pesan: "",
      password: "",
      password2: "",
      loading: false,
      loadingModal: false,
    };
  },
  mounted() {
    let vm = this;
    vm.email = this.$route.params.email;
  },
  methods: {
    cek() {
      let vm = this;
      if (vm.password2 == vm.password) {
        vm.same = true;
        vm.pesan = "";
      } else {
        vm.same = false;
        vm.pesan = "Password tidak sama!";
      }
    },

    resetPass() {
      let vm = this;
      vm.loading = true;
      axios
        .post("resetPassword/compare", {
          email: vm.email,
          kode: vm.otp,
          newPassword: vm.password,
        })
        .then((res) => {
          if (res.data.message == "password telah berhasil di ganti") {
            vm.loading = false;
            this.$root.$emit("bv::show::modal", "modal-success");
          } else {
            vm.loading = false;
            this.$root.$emit("bv::show::modal", "modal-failed");
          }
        });
    },

    doResend() {
      let vm = this;
      vm.loadingModal = true;

      axios
        .post("resetPassword/reset", {
          email: vm.email,
        })
        .then((res) => {
          if (res.data.message) {
            vm.loadingModal = false;
            this.$root.$emit("bv::hide::modal", "modal-failed");
          }
        });
    },

    show() {
      this.$root.$emit("bv::show::modal", "modal-failed");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white2": #fff,
);

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  .title {
    font-family: "nunito", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: map-get($color, "blue");
  }
  .sub-title {
    font-family: "nunito", sans-serif;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    margin-top: 2%;
    .bold {
      font-weight: 700;
    }
  }

  .form {
    margin-top: 2%;
    width: 70%;
    .pesan {
      font-family: "nunito", sans-serif;
      font-weight: 700;
      font-size: 20px;
      color: map-get($color, "red");
      text-align: center;
    }
    .inputs {
      height: 57px;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      font-size: 16px;
      margin: 3% 0;
      border-radius: 6px;
      text-align: center;
    }
    .button-wrapper {
      width: 100%;
      margin-top: 7%;
      .btn {
        width: 100%;
        height: 57px;
        font-family: "nunito", sans-serif;
        font-weight: 700;
        text-transform: capitalize;
        font-size: 16px;
        border-radius: 6px;
        background-color: map-get($color, "blue");
        border: 1px solid map-get($color, "blue");
        box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
      }
      .sign-in {
        font-family: "nunito", sans-serif;
        text-transform: capitalize;
        color: map-get($color, "black");
        font-weight: 300;
        font-size: 20px;
        margin-top: 8%;
        .biru {
          color: map-get($color, "blue");
          cursor: pointer;
          font-weight: 700;
          text-transform: capitalize;
        }
      }
    }
  }
}

.wrapper2 {
  background-image: url("../../assets/vector/Pattern.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .ads {
    width: 80%;
    height: 25%;
    border-radius: 10px;
    background-color: map-get($color, "white2");
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
